import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Loading from "../common/Loading";
import { ClientsApi } from "@unity/components";

import { CanDelete } from "../services/Permissions";
import { AppHeaderComponent, AppButtonComponent } from "@unity/components";
import { RenderPageTemplate } from "../mapping";

export default function Clients({ history, context, ClientsApi }) {
  const [data, setData] = useState(false);
  const [open, setOpen] = useState({ state: false, data: false });
  const clients = async () => {
    const data = await ClientsApi.getClients();
    setData(data.data);
  };

  useEffect(() => {
    clients();

  }, []);

  const columns = [
    {
      name: "",
      label: "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].logo_path ? (
            <div
              style={{
                backgroundImage: "url(" + data[dataIndex].logo_path + ")",
                backgroundPosition: "center, center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: 50,
                width: 50,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#d2d2d2",
                borderRadius: "100%",
                display: "inline-block",
              }}
            />
          ) : (
            <div
              style={{
                backgroundImage:
                  "url(" +
                  "https://unity.world/wp-content/uploads/brizy/9/assets/images/iW=167&iH=44&oX=1&oY=0&cW=164&cH=44/Unity-Group.png" +
                  ")",
                backgroundPosition: "center, center",
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                height: 50,
                width: 50,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#d2d2d2",
                borderRadius: "100%",
                display: "inline-block",
              }}
            />
          );
        },
      },
    },
    { name: "name", label: "Name" },
    { name: "email", label: "Email" },
    { name: "phone", label: "Phone" },
    { name: "mobile", label: "Mobile" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const edit = true;

          return (
            <div>
              <Link
                to={{
                  pathname: `/clients/view/${data[dataIndex].id}`,
                  state: { client: data[dataIndex].id },
                }}
              >
                <AppButtonComponent
                  text="view"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>
              {edit && (
                <Link
                  to={{
                    pathname: `/clients/edit/${data[dataIndex].id}`,
                    state: { client: data[dataIndex].id },
                  }}
                >
                  <AppButtonComponent
                    text="edit"
                    btnIcon="edit"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </Link>
              )}
              <AppButtonComponent
                text="call"
                btnIcon="call"
                style={{
                  background: "green",
                  color: "white",
                  marginLeft: 10,
                }}
                onClick={() => setOpen({ state: true, data: data[dataIndex] })}
              />
            </div>
          );
        },
        filter: false,
      },
    },
  ];

  var select;

  if (CanDelete("clients")) {
    select = "multiple";
  } else {
    select = "none";
  }

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    selectableRows: select,
    rowsPerPage: 10,
    page: 0,
    onRowsDelete: (rowsDeleted) => {
      rowsDeleted.data.map(async (item, key) => {
        await ClientsApi.deleteClient(data[item.dataIndex].id);
      });
    },
  };

  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "index");

  if (data) {
    return (
      <>
        <RenderPage
          history={history}
          context={context}
          options={options}
          data={data}
          columns={columns}
          open={open}
          setOpen={setOpen}
        />
      </>
    );
  } else {
    return <Loading />;
  }
}
